import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import colors from 'assets/styles/colors';
import MarcaBadge from 'features/common-staff/views/marcaBadge';
import { Tracking } from 'modules';
import { styled } from '@mui/material/styles';
import './listRow.scss';
import { safeConcat } from 'utils/format';
import CircleAlertYellowIcon from 'assets/icons/circle-alert-yellow';
import ButtonTooltipIcon from 'features/common/buttonTooltipIcon';
import { useHistory } from 'react-router-dom';
import TooltipMensagem from 'features/common/tooltipMensagem';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import formatStatus, { formatDate, formatValue } from '../../../helpers/formatStatus';

const TooltipStatus = styled(({
  className, title, children, arrow, placement,
}) => (
  <Tooltip title={title} classes={{ tooltip: className, arrow: 'arrow' }} arrow={arrow} placement={placement}>
    {children}
  </Tooltip>
))`
    color: #FFFFFF;
    font-family: CircularStd, sans-serif !important;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 120%;
`;

const useStyles = makeStyles({
  selected: {
    background: (openSidebar) => (
      openSidebar
        ? `${colors.primary_color_100_36}`
        : '#FFFFFF'
    ),
    borderBottom: (openSidebar) => (
      openSidebar
        ? `${colors.primary_color_100_56}`
        : '1px solid #EDF1F7'
    ),
    '&:hover': {
      background: (openSidebar) => (
        openSidebar
          ? `${colors.primary_color_100_36}`
          : `${colors.secundary_color_100_24}`
      ),
    },
  },
  modificado: {
    borderRadius: '2px',
    background: (openSidebar) => (
      openSidebar ? `${colors.alert_color_100_24}` : `${colors.alert_color_100_08}`
    ),
    border: (openSidebar) => (
      openSidebar ? `${colors.alert_color_100_48}` : `${colors.secundary_color_100_50}`
    ),
    '&:hover': {
      background: (openSidebar) => (
        openSidebar
          ? `${colors.alert_color_100_24}`
          : `${colors.alert_color_100_16}`
      ),
      border: (openSidebar) => (
        openSidebar
          ? `${colors.alert_color_100_48}`
          : `${colors.alert_color_100_36}`
      ),
    },
  },
  docPendente: {
    borderRadius: '2px',
    background: `${colors.error_color_100_08}`,
    border: `${colors.error_color_100_56}`,
    '&:hover': {
      background:
        `${colors.error_color_100_16}`,
      border: `${colors.error_color_100_56}`,
    },
  },
});

const whitelistCadastro = [
  'pendente_documentos',
  'documentos_enviados_parcialmente',
  'documentos_atualizados_dealer',

  'pendente_docs_juridico_dealer',
  'docs_juridico_enviados_parcialmente',
  'docs_juridico_atualizado_dealer',

  'pendente_docs_formalizar_dealer',
  'docs_formalizar_enviados_parcialmente',
  'docs_formalizar_atualizado_dealer',

  'assinatura_docusign',
  'docs_somente_formalizar',
  'docs_formalizar_enviados_sem_retorno',
  'docusing_docs_formalizar_sem_retorno',
];

const statusPendenteDocs = [
  'pendente_documentos',
  'documentos_enviados_parcialmente',

  'pendente_docs_juridico_dealer',
  'docs_juridico_enviados_parcialmente',

  'pendente_docs_formalizar_dealer',
  'docs_formalizar_enviados_parcialmente',
];

const statusDescriptions = {
  aguardando_aprovacao_dealer: 'Analise a proposta de limite, aceitando ou recusando a mesma.',
  aprovacao_aceita_dealer: 'Proposta aceita. Sua aprovação está em análise pelo Banco Stellantis.',
  aprovacao_recusada_dealer: 'Proposta recusada. Você pode aceitá-la a qualquer momento.',
  enviado_para_cadastro: 'Aguarde solicitação de documentos cadastrais pelo Banco Stellantis.',
  renovacao_cadastral: 'Documentos já solicitados em outra aprovação. Aguardando próxima etapa.',
  pendente_documentos: 'Envie os documentos solicitados pelo Banco Stellantis.',
  documentos_enviados_parcialmente: 'Envie os documentos faltantes.',
  documentos_atualizados_dealer: 'Documentos enviados. Aguardando validação pelo Banco Stellantis.',
  cadastro_atualizado: 'Documentos validados. Aguardando próxima etapa.',
  enviado_para_juridico: 'Documentos para formalização em elaboração pelo Banco Stellantis.',
  analise_financiamento_rede: 'Aprovação em análise pelo seu analista.',
  atualizacao_docs_juridico: 'Documentos já solicitados em outra aprovação. Aguardando próxima etapa.',
  pendente_docs_juridico_dealer: 'Envie os documentos complementares solicitados pelo Banco Stellantis.',
  docs_juridico_enviados_parcialmente: 'Envie os documentos complementares faltantes.',
  docs_juridico_atualizado_dealer: 'Documentos complementares enviados. Aguardando validação pelo Banco Stellantis.',
  analise_juridico: 'Documentos complementares em análise pelo Banco Stellantis.',
  pendente_docs_formalizar_dealer: 'Envie os documentos para formalização solicitados pelo Banco Stellantis.',
  docs_formalizar_enviados_parcialmente: 'Envie os documentos para formalização faltantes.',
  docs_formalizar_atualizado_dealer: 'Documentos para formalização enviados. Aguardando validação pelo Banco Stellantis.',
  docs_formalizar_enviados_sem_retorno: 'Documentos formalizados. Aguardando próxima etapa.',
  docusing_docs_formalizar_sem_retorno: 'Documentos enviados. Aguardando assinatura via Docusign e demais formalizações.',
  assinatura_docusign: 'Documentos em processo de assinatura via Docusign.',
  enviado_para_credito: 'Sua aprovação está em análise pelo Banco Stellantis.',
  obs_do_credito: 'Sua aprovação está em análise pelo Banco Stellantis.',
  aprovacao_finalizada: 'Processo de formalização concluído. Solicite o aumento do limite desejado, na tela “Aumento/reduç. Limite”, no HUB.',
  aprovacao_pausada: 'Sua aprovação foi pausada e está em análise pelo Banco Stellantis.',
};

const truncateText = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

const ListRow = ({
  data, openSidebar, getDetalheLimite, setCadastroPage,
  gridTemplate, setLimiteSelected, setOpenSidebar, isSelected,
}) => {
  const classes = useStyles(isSelected && openSidebar);

  const history = useHistory();
  const navTo = (itemName) => {
    history.replace(`${itemName}`);
  };

  const renderMarca = (marca) => {
    if (marca?.match(/FIAT$/i)) {
      return 'fiat';
    } if (marca?.match(/CJDR$/i)) {
      return 'chrysler';
    }
    return 'default';
  };

  const handleLimite = () => {
    setLimiteSelected(data);
    getDetalheLimite(data.idLimite, data);
    if (whitelistCadastro.includes(data.statusLimite)) {
      setCadastroPage(true);
      navTo('lista-documentos');
      Tracking.Mixpanel.listaLimitesAprovadosHub.trackLinhaProdutoComAprovacao('Limites aprovados');
    } else {
      setOpenSidebar(true);
    }
  };

  const renderTooltip = (title) => (
    <ButtonTooltipIcon title={title}>
      <CircleAlertYellowIcon width="12" height="12" className="circle-alert" />
    </ButtonTooltipIcon>
  );

  const shouldRenderNotify = Boolean(data?.condicao?.modificado
    || data?.valorLimite?.modificado || data?.dataVencimento?.modificado);

  const renderClassName = () => {
    if (statusPendenteDocs.includes(data.statusLimite)) {
      return classes.docPendente;
    } if (shouldRenderNotify) {
      return classes.modificado;
    }
    return '';
  };

  const renderIconDocPendente = () => (
    <TooltipMensagem
      title="Pendência de documentos"
    >
      <ErrorOutlineIcon style={{
        color: '#C31E10', width: 16, height: 16, marginLeft: '8px',
      }}
      />
    </TooltipMensagem>
  );

  return (
    <div className={`${renderClassName()} list-row ${gridTemplate?.container} ${isSelected ? classes.selected : ''}`} role="button" tabIndex={0} onClick={handleLimite}>
      <div className="list-row__id">
        {safeConcat('#', data?.idLimite)}
        {data?.condicao?.modificado && renderTooltip('As condições da aprovação foram alteradas.')}
        {statusPendenteDocs.includes(data.statusLimite) ? renderIconDocPendente() : null}
      </div>
      <div className="list-row__produto">
        <MarcaBadge marca={renderMarca(data?.descricaoProduto)} />
        {data?.descricaoProduto}
      </div>
      {!openSidebar && <div className="list-row__aprovacao">{formatDate(data?.dataAprovacao)}</div>}
      <div className="list-row__valor">
        {formatValue(data?.valorLimite?.valor)}
        {data?.valorLimite?.modificado && renderTooltip('Valor de aprovação alterado.')}
      </div>
      <div className="list-row__vencimento">
        {formatDate(data?.dataVencimento?.valor)}
        {data?.dataVencimento?.modificado && renderTooltip('Data de vencimento alterada.')}
      </div>
      {!openSidebar && (
        <div className="list-row__status">
          <TooltipStatus title={truncateText(statusDescriptions[data?.statusLimite] || '', 251)} arrow>
            <span>{formatStatus(data?.statusLimite)}</span>
          </TooltipStatus>
        </div>
      )}
    </div>
  );
};

ListRow.propTypes = {
  data: PropTypes.object,
  openSidebar: PropTypes.bool,
  gridTemplate: PropTypes.object,
  setLimiteSelected: PropTypes.func,
  setOpenSidebar: PropTypes.func,
  getDetalheLimite: PropTypes.func,
  setCadastroPage: PropTypes.func,
  isSelected: PropTypes.bool,
};

ListRow.defaultProps = {
  data: null,
  openSidebar: false,
  gridTemplate: {},
  setLimiteSelected: () => { },
  setOpenSidebar: () => { },
  getDetalheLimite: () => { },
  setCadastroPage: () => { },
  isSelected: false,
};

export default ListRow;
