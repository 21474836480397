import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { formatCodigoConcessionaria } from 'utils/format';
import formatStatus, { formatDate, formatValue } from '../../helpers/formatStatus';
import DrawerProposta from '../drawerProposta';
import LimitesAprovadosList from '../limitesAprovadosList/limitesAprovadosList';
import './limitesAprovadosForm.scss';
import LimitesAprovadosSelector from './selector/limitesAprovadosSelector';

const drawerWidth = 372;
const Main = styled('main')(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  position: 'relative',
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

const detailsFormatter = (detail, brand) => ({
  brand,
  nome: detail?.descricaoProduto,
  condicao: detail?.condicao,
  idLimite: detail?.idLimite,
  codigoBuc: detail?.codDealer,
  statusLimite: detail?.statusLimite,
  data: [
    { chave: 'Data de aprovacao', valor: formatDate(detail?.dataAprovacao) },
    { chave: 'Limite total aprovado', valor: formatValue(detail?.valorLimite?.valor), modificado: detail?.valorLimite?.modificado },
    { chave: 'Data de vencimento', valor: formatDate(detail?.dataVencimento?.valor), modificado: detail?.dataVencimento?.modificado },
    { chave: 'Status', valor: formatStatus(detail?.statusLimite) },
    { chave: 'Código Buc', valor: formatCodigoConcessionaria(detail?.codDealer) },
  ],
});

const matrizContainerStyle = (open) => ({ margin: open ? '41px 10px 36px 15px' : '41px 34px 36px 15px' });

const LimitesAprovadosForm = ({
  limitesAprovadosList, concessionarias, matriz, setMatriz,
}) => {
  const [value, setValue] = useState('');
  const [limites, setLimites] = useState([]);
  const [limiteSelected, setLimiteSelected] = useState(null);
  const [brandAtual, setBrandAtual] = useState();
  const [open, setOpen] = useState(false);
  const enabledAceitar = (limiteSelected?.statusLimite === 'aguardando_aprovacao_dealer'
    || limiteSelected?.statusLimite === 'aprovacao_recusada_dealer');
  const enabledRecusar = (limiteSelected?.statusLimite === 'aguardando_aprovacao_dealer'
    || limiteSelected?.statusLimite === 'aprovacao_aceita_dealer');

  const handleMatrizChange = (matrizReceive) => {
    setMatriz(matrizReceive);
  };

  useEffect(() => {
    const brandMatriz = concessionarias.find((m) => m.dealerName === matriz?.dealerName)?.brand || '';
    setLimites(limitesAprovadosList);
    setBrandAtual(brandMatriz);
    setOpen(false);
  }, [matriz?.dealerName, limitesAprovadosList]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Main open={open} style={{ padding: 0 }}>
        <div className="matriz-container" style={matrizContainerStyle(open)}>
          <LimitesAprovadosSelector
            setNewDealer={handleMatrizChange}
            items={concessionarias}
            selectedItem={matriz}
          />
        </div>
        <LimitesAprovadosList
          limites={limites}
          setLimites={setLimites}
          openSidebar={open}
          setOpenSidebar={setOpen}
          setLimiteSelected={setLimiteSelected}
          limiteSelected={limiteSelected}
        />
      </Main>
      <DrawerProposta
        open={open}
        setOpen={setOpen}
        title="Proposta de aprovação"
        detailData={detailsFormatter(limiteSelected, brandAtual)}
        valueCondicao={value}
        setValueCondicao={setValue}
        enabledAceitar={enabledAceitar}
        enabledRecusar={enabledRecusar}
      />
    </Box>
  );
};

LimitesAprovadosForm.propTypes = {
  limitesAprovadosList: PropTypes.array,
  concessionarias: PropTypes.array,
  matriz: PropTypes.object,
  setMatriz: PropTypes.func,
};

LimitesAprovadosForm.defaultProps = {
  limitesAprovadosList: [],
  concessionarias: [],
  matriz: null,
  setMatriz: () => { },
};

export default LimitesAprovadosForm;
