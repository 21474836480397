import actions from './actions';

const INITIAL_STATE = {
  limiteUpdate: {
    isLoading: false,
    isError: false,
  },
  setSelectedItems: [],
  documento: {
    isLoading: false,
    isError: false,
    documentoList: [],
  },
  relacionamento: {
    isLoading: false,
    isError: false,
    relacionamentoList: [],
  },
  pessoaDocumentacao: {
    isLoading: false,
    isError: false,
    listaDocumento: [],
  },

  isLoading: false,
  isError: false,

  listaPessoaDocumentacao: {
    isLoading: true,
    isError: false,
    listaDados: [],
    listaDocumentos: [],
  },

  listaDocumentosComplementares: {
    isLoading: true,
    isError: false,
    listaDados: [],
  },

  listaDocumentosFormalizar: {
    isLoading: true,
    isError: false,
    editado: false,
    listaDados: [],
  },

  gerenciadorDocumentos: [],
};

const reduceUpdateLimite = (state, action) => {
  switch (action.type) {
    case actions.types.UPDATE_LIMITE_START:
      return {
        ...state,
        limiteUpdate: {
          ...state.limiteUpdate,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.UPDATE_LIMITE_SUCCESS:
      return {
        ...state,
        limiteUpdate: {
          ...state.limiteUpdate,
          isLoading: false,
          isError: false,
        },
      };
    case actions.types.UPDATE_LIMITE_ERROR:
      return {
        ...state,
        limiteUpdate: {
          ...state.limiteUpdate,
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  if (action.type.startsWith(actions.types.PREFIX_LIMITES_APROVADOS_UPDATE)) {
    return reduceUpdateLimite(state, action);
  }
  switch (action.type) {
    case actions.types.INSERT_TIPO_RELACIONAMENTO_START:
      return {
        ...state,
        relacionamento: {
          ...state.relacionamento,
          isLoading: true,
          isError: false,
          relacionamentoList: [],
        },
      };
    case actions.types.INSERT_TIPO_RELACIONAMENTO_SUCCESS:
      return {
        ...state,
        relacionamento: {
          ...state.relacionamento,
          isLoading: false,
          isError: false,
          relacionamentoList: action.payload.relacionamentoList,
        },
      };
    case actions.types.INSERT_TIPO_RELACIONAMENTO_ERROR:
      return {
        ...state,
        relacionamento: {
          ...state.relacionamento,
          isLoading: false,
          isError: true,
          relacionamentoList: [],
        },
      };
    case actions.types.INSERT_TIPO_DOCUMENTO_START:
      return {
        ...state,
        documento: {
          ...state.documento,
          isLoading: true,
          isError: false,
          documentoList: [],
        },
      };
    case actions.types.INSERT_TIPO_DOCUMENTO_SUCCESS:
      return {
        ...state,
        documento: {
          ...state.documento,
          isLoading: false,
          isError: false,
          documentoList: action.payload.documentoList,
        },
      };
    case actions.types.INSERT_TIPO_DOCUMENTO_ERROR:
      return {
        ...state,
        documento: {
          ...state.documento,
          isLoading: false,
          isError: true,
          documentoList: [],
        },
      };
    case actions.types.INSERT_PESSOA_DOCUMENTACAO_START:
      return {
        ...state,
        pessoaDocumentacao: {
          ...state.pessoaDocumentacao,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.INSERT_PESSOA_DOCUMENTACAO_SUCCESS:
      return {
        ...state,
        pessoaDocumentacao: {
          ...state.pessoaDocumentacao,
          isLoading: false,
          isError: false,
        },
      };
    case actions.types.INSERT_PESSOA_DOCUMENTACAO_ERROR:
      return {
        ...state,
        pessoaDocumentacao: {
          ...state.pessoaDocumentacao,
          isLoading: false,
          isError: true,
        },
      };
    case actions.types.GET_PESSOA_DOCUMENTACAO_START:
      return {
        ...state,
        listaPessoaDocumentacao: {
          ...state.listaPessoaDocumentacao,
          isLoading: true,
          isError: false,
          listaDados: [],
        },
      };
    case actions.types.GET_PESSOA_DOCUMENTACAO_SUCCESS:
      return {
        ...state,
        listaPessoaDocumentacao: {
          ...state.listaPessoaDocumentacao,
          isLoading: false,
          isError: false,
          listaDados: action.payload.listaPessoaDocumentacao ?? [],
        },
        documento: {
          ...state.documento,
          documentoList: action.payload.documentoList,
        },
        relacionamento: {
          ...state.relacionamento,
          relacionamentoList: action.payload.relacionamentoList,
        },
      };
    case actions.types.GET_PESSOA_DOCUMENTACAO_ERROR:
      return {
        ...state,
        listaPessoaDocumentacao: {
          ...state.listaPessoaDocumentacao,
          isLoading: false,
          isError: true,
          listaDados: [],
        },
      };
    case actions.types.SET_SELECTED_ITEMS:
      return {
        ...state,
        setSelectedItems: action.payload.idTipo,
      };
    case actions.types.DELETE_PESSOA_DOCUMENTACAO_START:
      return {
        ...state,
        listaPessoaDocumentacao: {
          ...state.listaPessoaDocumentacao,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.DELETE_PESSOA_DOCUMENTACAO_SUCCESS:
      return {
        ...state,
        listaPessoaDocumentacao: {
          ...state.listaPessoaDocumentacao,
          isLoading: false,
          isError: false,
        },
      };
    case actions.types.DELETE_PESSOA_DOCUMENTACAO_ERROR:
      return {
        ...state,
        listaPessoaDocumentacao: {
          ...state.listaPessoaDocumentacao,
          isLoading: false,
          isError: true,
        },
      };
    case actions.types.BUILD_GERENCIADOR:
      return {
        ...state,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.DOWNLOAD_DOCUMENTO_START:
      return {
        ...state,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.DOWNLOAD_DOCUMENTO_SUCCESS:
      return {
        ...state,
        urlDownload:
          action.payload.urlDownload,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.DOWNLOAD_DOCUMENTO_ERROR:
      return {
        ...state,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.UPDATE_DOCUMENTO_START:
      return {
        ...state,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.UPDATE_DOCUMENTO_SUCCESS:
      return {
        ...state,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.UPDATE_DOCUMENTO_ERROR:
      return {
        ...state,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.DELETE_DOCUMENTO_START:
      return {
        ...state,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.DELETE_DOCUMENTO_SUCCESS:
      return {
        ...state,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.DELETE_DOCUMENTO_ERROR:
      return {
        ...state,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.SET_DOCUMENTO_ERROR:
      return {
        ...state,
        gerenciadorDocumentos: action.payload.gerenciadorDocumentos,
      };
    case actions.types.GET_DOCUMENTOS_COMPLEMENTARES_START:
      return {
        ...state,
        listaDocumentosComplementares: {
          ...state.listaDocumentosComplementares,
          isLoading: true,
          isError: false,
          listaDados: [],
        },
      };
    case actions.types.GET_DOCUMENTOS_COMPLEMENTARES_SUCCESS:
      return {
        ...state,
        listaDocumentosComplementares: {
          ...state.listaDocumentosComplementares,
          isLoading: false,
          isError: false,
          listaDados: action.payload.listaDocumentosComplementares,
        },
      };
    case actions.types.GET_DOCUMENTOS_COMPLEMENTARES_ERROR:
      return {
        ...state,
        listaDocumentosComplementares: {
          ...state.listaDocumentosComplementares,
          isLoading: false,
          isError: true,
          listaDados: [],
        },
      };
    case actions.types.INSERT_DOCUMENTO_COMPLEMENTAR:
      return {
        ...state,
        listaDocumentosComplementares: {
          ...state.listaDocumentosComplementares,
          listaDados: [
            ...state
              .listaDocumentosComplementares
              .listaDados
              .toSpliced(action.payload.indexPessoa, 1, {
                ...state
                  .listaDocumentosComplementares
                  .listaDados[action.payload.indexPessoa],
                documentoPendente: [
                  ...state
                    .listaDocumentosComplementares
                    .listaDados[action.payload.indexPessoa]
                    .documentoPendente
                    .toSpliced(action.payload.indexDocumento, 1, {
                      ...state
                        .listaDocumentosComplementares
                        .listaDados[action.payload.indexPessoa]
                        .documentoPendente[action.payload.indexDocumento],
                      documentoId: action.payload.documento.id,
                      nomeGuid: action.payload.documento.nomeGuid,
                      documentoNome: action.payload.documento.nome,
                      documentoTamanho: action.payload.documento.tamanho,
                      temporario: action.payload.documento.temporario,
                      paraExcluir: false,
                    }),
                ],
              }),
          ],
        },
      };
    case actions.types.DELETE_DOCUMENTO_COMPLEMENTAR:
      return {
        ...state,
        listaDocumentosComplementares: {
          ...state.listaDocumentosComplementares,
          listaDados: [
            ...state
              .listaDocumentosComplementares
              .listaDados
              .toSpliced(action.payload.indexPessoa, 1, {
                ...state
                  .listaDocumentosComplementares
                  .listaDados[action.payload.indexPessoa],
                documentoPendente: [
                  ...state
                    .listaDocumentosComplementares
                    .listaDados[action.payload.indexPessoa]
                    .documentoPendente
                    .toSpliced(action.payload.indexDocumento, 1, {
                      ...state
                        .listaDocumentosComplementares
                        .listaDados[action.payload.indexPessoa]
                        .documentoPendente[action.payload.indexDocumento],
                      ...(action.payload.documentoId
                        ? { paraExcluir: true, temporario: true } : { temporario: false }),
                      documentoNome: null,
                      documentoTamanho: null,
                    }),
                ],
                idsDocumentosExcluidos: [
                  ...(state
                    .listaDocumentosComplementares
                    .listaDados[action.payload.indexPessoa]
                    ?.idsDocumentosExcluidos || []),
                  ...(action.payload.documentoId && !state
                    .listaDocumentosComplementares
                    .listaDados[action.payload.indexPessoa]
                    ?.idsDocumentosExcluidos?.includes(action.payload.documentoId)
                    ? [action.payload.documentoId]
                    : []),
                ],
              }),
          ],
        },
      };
    case actions.types.GET_DOCUMENTOS_FORMALIZAR_START:
      return {
        ...state,
        listaDocumentosFormalizar: {
          ...state.listaDocumentosFormalizar,
          isLoading: true,
          isError: false,
          listaDados: [],
        },
      };
    case actions.types.GET_DOCUMENTOS_FORMALIZAR_SUCCESS:
      return {
        ...state,
        listaDocumentosFormalizar: {
          ...state.listaDocumentosFormalizar,
          isLoading: false,
          isError: false,
          listaDados: action.payload.listaDocumentosFormalizar,
        },
      };
    case actions.types.GET_DOCUMENTOS_FORMALIZAR_ERROR:
      return {
        ...state,
        listaDocumentosFormalizar: {
          ...state.listaDocumentosFormalizar,
          isLoading: false,
          isError: true,
          listaDados: [],
        },
      };
    case actions.types.APPEND_DOCUMENTO_FORMALIZAR:
      return {
        ...state,
        listaDocumentosFormalizar: {
          ...state.listaDocumentosFormalizar,
          listaDados: [
            ...state
              .listaDocumentosFormalizar
              .listaDados
              .toSpliced(action.payload.indexList, 1, {
                ...state
                  .listaDocumentosFormalizar
                  .listaDados[action.payload.indexList],
                listaArquivos: [
                  ...state
                    .listaDocumentosFormalizar
                    .listaDados[action.payload.indexList]
                    .listaArquivos,
                  action.payload.anexo,
                ],
              }),
          ],
        },
      };
    case actions.types.INSERT_DOCUMENTO_FORMALIZAR:
      return {
        ...state,
        listaDocumentosFormalizar: {
          ...state.listaDocumentosFormalizar,
          editado: true,
          listaDados: [
            ...state
              .listaDocumentosFormalizar
              .listaDados
              .toSpliced(action.payload.indexList, 1, {
                ...state
                  .listaDocumentosFormalizar
                  .listaDados[action.payload.indexList],
                listaArquivos: [
                  ...state
                    .listaDocumentosFormalizar
                    .listaDados[action.payload.indexList]
                    .listaArquivos,
                  { ...action.payload.documento },
                ],
              }),
          ],
        },
      };
    case actions.types.DELETE_DOCUMENTO_FORMALIZAR:
      return {
        ...state,
        listaDocumentosFormalizar: {
          ...state.listaDocumentosFormalizar,
          editado: true,
          listaDados: [
            ...state
              .listaDocumentosFormalizar
              .listaDados
              .toSpliced(action.payload.indexList, 1, {
                ...state
                  .listaDocumentosFormalizar
                  .listaDados[action.payload.indexList],
                listaArquivos: [
                  ...state
                    .listaDocumentosFormalizar
                    .listaDados[action.payload.indexList]
                    .listaArquivos
                    .filter((arquivo) => arquivo.nomeGuid !== action.payload.nomeGuid),
                ],
              }),
          ],
        },
      };
    case actions.types.INSERT_DOCUMENTO_CADASTRO:
      return {
        ...state,
        gerenciadorDocumentos: [
          ...state
            .gerenciadorDocumentos
            .toSpliced(action.payload.indexDocumento, 0, {
              ...action.payload.anexo,
              nomeDocumento: action.payload.nomeDocumento,
              idPessoaDocumento: action.payload.idPessoaDocumento,
              temporario: true,
              paraExcluir: false,
            }),
        ],
      };
    case actions.types.DELETE_DOCUMENTO_CADASTRO:
      return {
        ...state,
        gerenciadorDocumentos: [
          ...state.gerenciadorDocumentos
            .toSpliced(action.payload.indexDocumento, 1, {
              ...state.gerenciadorDocumentos[action.payload.indexDocumento],
              nome: null,
              tamanho: null,
              paraExcluir: !!action.payload.idDocumento,
              temporario: !!action.payload.idDocumento,
            }),
        ],
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
