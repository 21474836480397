import { React } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './infoCadastroList.scss';
import { Divider } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import TooltipMensagem from 'features/common/tooltipMensagem';
import SummaryPage from 'features/common/summaryPage';
import { maskToCPFOrCNPJ } from 'utils/masks';
import RadioGroupPessoa from './radioGroupPessoa/radioGroupPessoa';
import UploadDocumento from '../../uploadDocumento';
import DownloadDocumento from '../../downloadDocumento';

const InfoCadastroList = ({
  listaPessoaDocumentacao,
  gerenciadorDocumentos,
  statusLimite,
}) => {
  const renderItem = (text, item) => (
    <div className="modal-cadastro__content__documentos_itens">
      <span className="modal-cadastro__content__documentos__text-label">{text}</span>
      <div className="modal-cadastro__content__documentos__text-info">{item}</div>
    </div>
  );
  const subtitle = 'Insira os documentos referentes a atualização do cadastro da aprovação e em seguida clique em enviar para cadastro.';
  const getIdDocumento = (nomeDocumento) => {
    const documento = listaPessoaDocumentacao?.documentos.find(
      (doc) => doc?.nomeDocumento === nomeDocumento,
    );
    return documento?.idPessoaDocumentacao;
  };

  const getDocumentoCadastro = (nomeDocumento, idPessoaDocumento) => gerenciadorDocumentos?.find(
    (doc) => doc.nomeDocumento === nomeDocumento
    && doc.idPessoaDocumento === idPessoaDocumento
    && !_.isEmpty(doc.documentosCadastro),
  )?.documentosCadastro;

  const isAnexoRemovido = (tipoDocumento) => {
    const documento = listaPessoaDocumentacao?.documentos.find(
      (doc) => doc?.nomeDocumento === tipoDocumento,
    );
    if (!documento
      && tipoDocumento.motivoRemocao?.find((motivoRemocao) => motivoRemocao !== null)) return true;
    return false;
  };

  const getDocumento = (nomeDocumento, idPessoaDocumento) => {
    const result = gerenciadorDocumentos?.find(
      (doc) => doc?.nomeDocumento === nomeDocumento
    && doc?.idPessoaDocumento === idPessoaDocumento,
    );
    return result;
  };
  const statusFluxoCadastro = [
    'pendente_documentos',
    'documentos_enviados_parcialmente',
    'documentos_atualizados_dealer',
  ];
  const enableDeleteDocument = (nomeDocumento, idPessoaDocumento) => {
    const documento = gerenciadorDocumentos?.find(
      (doc) => doc?.nomeDocumento === nomeDocumento
        && doc?.idPessoaDocumento === idPessoaDocumento,
    );
    return !documento?.validado && statusFluxoCadastro.includes(statusLimite);
  };

  const documentosIncompletos = () => !gerenciadorDocumentos.every((item) => item.nome);

  const renderIcon = () => {
    if (documentosIncompletos()) {
      return (
        <TooltipMensagem
          title="Há documentos pendentes para envio"
        >
          <ErrorOutlineIcon style={{ color: '#C31E10', width: 16, height: 16 }} />
        </TooltipMensagem>
      );
    }
    return (
      <TooltipMensagem
        title="Lista enviada"
      >
        <CheckRoundedIcon style={{ color: '#206446', width: 16, height: 16 }} />
      </TooltipMensagem>
    );
  };

  const renderDocumento = (item, index) => {
    const documentoCadastro = getDocumentoCadastro(
      item,
      listaPessoaDocumentacao?.pessoaDocumentacao.idPessoaDocumentacao,
    );
    const hasDocumentosCadastro = !!documentoCadastro;
    const downloadDocumentoComponent = (hasDocumentosCadastro && (
      <DownloadDocumento
        label="Documento do Cadastro"
        documento={documentoCadastro}
      />
    ));
    const uploadDocumentoComponent = (
      <UploadDocumento
        idDocumento={getIdDocumento(item)}
        anexoRemovido={isAnexoRemovido(item)}
        documento={
          getDocumento(
            item,
            listaPessoaDocumentacao?.pessoaDocumentacao.idPessoaDocumentacao,
          )
        }
        showDeleteButton={enableDeleteDocument(
          item,
          listaPessoaDocumentacao?.pessoaDocumentacao.idPessoaDocumentacao,
        )}
        indexDocumento={index}
      />
    );

    return (
      <>
        <div
          key={index}
          className={`${hasDocumentosCadastro ? '' : 'modal-cadastro__content__documentos'}`}
        >
          {!hasDocumentosCadastro ? (
            <>
              {renderItem('Nome do documento', item)}
              {uploadDocumentoComponent}
            </>
          ) : (
            <>
              <div className="modal-cadastro__content__documentos" style={{ width: '46%' }}>
                {renderItem('Nome do documento', item)}
              </div>
              <div className="modal-cadastro__content__documentos">
                {downloadDocumentoComponent}
                {uploadDocumentoComponent}
              </div>
            </>
          )}
        </div>
        <Divider />
      </>
    );
  };

  return (
    <SummaryPage
      title={listaPessoaDocumentacao?.pessoaDocumentacao.nome}
      IconTitle={renderIcon()}
      level={2}
    >
      <div className="modal-cadastro__content__sections">
        <div className="modal-cadastro__content_header">
          {renderItem('Relacionamento', listaPessoaDocumentacao?.pessoaDocumentacao.tipoRelacionamento)}
          <RadioGroupPessoa
            inputData={listaPessoaDocumentacao?.pessoaDocumentacao.tipoPessoa}
            disabled
          />
          {renderItem('CNPJ|CPF', maskToCPFOrCNPJ(listaPessoaDocumentacao?.pessoaDocumentacao.documento))}
        </div>
        <Divider />
        <div className="modal-cadastro__content__text-editor-title">
          <h1>Lista de documentos</h1>
          <span>
            {subtitle}
          </span>
        </div>
        {listaPessoaDocumentacao?.pessoaDocumentacao.tipoDocumento?.map((item, index) => (
          renderDocumento(item, index)
        ))}
      </div>
    </SummaryPage>
  );
};

InfoCadastroList.propTypes = {
  nome: PropTypes.string,
  listaPessoaDocumentacao: PropTypes.object,
  gerenciadorDocumentos: PropTypes.array,
  statusLimite: PropTypes.string,
};

InfoCadastroList.defaultProps = {
  nome: '',
  statusLimite: '',
  listaPessoaDocumentacao: null,
  gerenciadorDocumentos: [],
};

export default InfoCadastroList;
