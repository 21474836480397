import React, { useEffect, useState } from 'react';

import {
  Page, PageContent, PageHeader, PageSubTitle, PageTitle,
} from 'features/common-staff/layout/page';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SnackbarList from 'features/common-staff/snackbarList';
import { PageErrorBoundary } from 'features/common';
import BreadCrumbLimitesAprovados from './views/breadCrumbLimitesAprovados/breadCrumbLimitesAprovados';
import './limitesAprovadosPage.scss';
import LimitesAprovadosForm from './views/limitesAprovadosForm';

const LimitesAprovadosPage = ({
  getLimitesAprovados, concessionarias, snackbarErrors, onSnackbarClose,
}) => {
  const location = useLocation();
  const codigoDealer = location.state?.codigoDealer;

  const matrizFromCodigoDealer = codigoDealer
    ? concessionarias.find((c) => String(c.dealerId) === String(codigoDealer))
    : null;

  const initialMatriz = matrizFromCodigoDealer || concessionarias[0];
  const [matriz, setMatriz] = useState(initialMatriz);

  useEffect(() => {
    if (initialMatriz && initialMatriz.dealerId !== matriz?.dealerId) {
      setMatriz(initialMatriz);
    }
  }, [initialMatriz]);

  useEffect(() => {
    getLimitesAprovados(matriz.dealerId);
  }, [getLimitesAprovados, matriz]);
  return (
    <PageErrorBoundary>
      <Page>
        <PageHeader>
          <PageSubTitle>
            <BreadCrumbLimitesAprovados />
          </PageSubTitle>
          <PageTitle>Limites aprovados</PageTitle>
        </PageHeader>
        <PageContent>
          <LimitesAprovadosForm
            matriz={matriz}
            setMatriz={setMatriz}
            concessionarias={concessionarias}
          />
        </PageContent>
      </Page>
      <SnackbarList
        snackbarErrors={snackbarErrors}
        onClose={(id) => onSnackbarClose(id)}
      />
    </PageErrorBoundary>
  );
};

LimitesAprovadosPage.propTypes = {
  getLimitesAprovados: PropTypes.func,
  concessionarias: PropTypes.array,
  snackbarErrors: PropTypes.array,
  onSnackbarClose: PropTypes.func,
};

LimitesAprovadosPage.defaultProps = {
  getLimitesAprovados: () => {},
  concessionarias: [],
  snackbarErrors: [],
  onSnackbarClose: () => {},
};
export default LimitesAprovadosPage;
