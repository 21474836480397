import { Tracking } from 'modules';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import BasicDrawer from 'features/common/basicDrawer/basicdrawer';
import Button from 'features/common-staff/controls/button';
import PopperComponent from 'features/common/popperComponent/popperComponent';
import AlertFilledIcon from 'assets/icons/alert-filled';
import AlertCard from 'features/common/alertCard/alertCard';
import CircleAlertYellowIcon from 'assets/icons/circle-alert-yellow';
import colors from 'assets/styles/colors';
import DetailsCard from 'features/common/detailsCard';
import AlertModalInput from 'features/common/alertModalInput';
import { CircularProgress } from '@mui/material';
import MarcaBadge from 'features/common-staff/views/marcaBadge';
import CardHistorico from 'features/common/cardHistorico/cardHistorico';
import CardCondicoes from '../cardCondicoes/cardCondicoes';
import './drawerProposta.scss';
import ObservacoesAprovacao from '../../../observacoesAprovacao';

const useStyles = makeStyles({
  cancelButtonContent: {
    marginRight: '4px',
    marginLeft: '0px',
    height: '40px',
    width: '170px',
  },
  acceptButtonContent: {
    marginRight: '4px',
    marginLeft: '4px',
    height: '40px',
    width: '166px',
  },
});

const DrawerProposta = ({
  open, setOpen, title, subtitle, detailData, historicoLimite, historicoIsLoading,
  enviarProposta, enabledAceitar, enabledRecusar, salvarJustificativa, closeModal,
}) => {
  const [openCard, setOpenCard] = useState('observacoes');

  const handleOpenCard = (cardName) => {
    setOpenCard(cardName === openCard ? null : cardName);
  };
  const classes = useStyles();
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [motivo, setMotivo] = useState('');
  const [alert, setAlert] = useState(false);
  const [valueCondicao, setValueCondicao] = useState(false);
  useEffect(() => {
    if (open) {
      setValueCondicao(valueCondicao);
      setOpenCard(detailData?.statusLimite === 'aguardando_aprovacao_dealer' ? 'condicoes' : 'observacoes');
    } else {
      setValueCondicao(false);
      setAlert(false);
    }
  }, [open, valueCondicao, detailData.idLimite]);

  useEffect(() => {
    setAlert(false);
  }, [valueCondicao]);

  const handleAceitar = (event) => {
    if (!valueCondicao) {
      setAlert(alert ? null : event.currentTarget);
    } else {
      setOpen(false);
      enviarProposta(detailData?.idLimite, detailData?.codigoBuc, 'aprovacao_aceita_dealer');
      Tracking.Mixpanel.listaLimitesAprovadosHub.trackBotaoPropostaAprovacao('botao_aceitar_proposta_limite_aprovado', 'Limites aprovados');
    }
  };

  const handleRecusar = () => {
    const motivoWithLineBreaks = motivo.replace(/\n/g, '<br />');

    setOpen(false);
    setOpenAlertModal(false);
    salvarJustificativa(detailData?.idLimite, detailData?.codigoBuc, 'aprovacao_recusada_dealer', motivoWithLineBreaks);
    closeModal();
    setMotivo('');
  };

  const renderMarca = (marca) => {
    if (marca?.match(/FIAT$/i)) {
      return 'fiat';
    } if (marca?.match(/CJDR$/i)) {
      return 'chrysler';
    }
    return 'default';
  };

  return (
    <BasicDrawer open={open} setOpen={setOpen} title={title} subtitle={subtitle}>
      <div className="main">
        <div className="historico-drawer">
          {!historicoIsLoading
            ? (
              <CardHistorico
                textCardReceive={historicoLimite}
                open={openCard === 'historico'}
                handleOpenChange={() => handleOpenCard('historico')}
              />
            )
            : (
              <div className="loading-historico">
                <CircularProgress color="inherit" size="18px" />
              </div>
            )}

        </div>
        <div className="observacoes">
          <ObservacoesAprovacao
            limite={detailData}
            openDrawer={open}
            isDrawer
            open={openCard === 'observacoes'}
            handleSummaryDrawer={() => handleOpenCard('observacoes')}
          />
        </div>
        <div className="details">
          <DetailsCard
            key={1}
            data={detailData?.data}
            open={openCard === 'details'}
            handleOpenChange={() => handleOpenCard('details')}
          >
            <div className="detailTitle" data-cy="detail-name">
              <div className="detailTitle__title">
                <MarcaBadge marca={renderMarca(detailData?.nome)} />
                {detailData?.nome}
              </div>
            </div>
          </DetailsCard>
        </div>
        <div className="condicoes">
          <CardCondicoes
            value={valueCondicao}
            setValue={setValueCondicao}
            textCardReceive={detailData?.condicao?.valor}
            showAlert={alert}
            showTooltip={Boolean(detailData?.condicao?.modificado)}
            open={openCard === 'condicoes'}
            handleOpenChange={() => handleOpenCard('condicoes')}
          />
        </div>
        <div className="footer">
          <Button
            className={classes.cancelButtonContent}
            onClick={() => {
              setOpenAlertModal(true);
              Tracking.Mixpanel.listaLimitesAprovadosHub.trackBotaoPropostaAprovacao('botao_recusar_proposta_limite_aprovado', 'Limites aprovados');
            }}
            dataCy="botao-recusar"
            color="darkGray"
            disabled={!enabledRecusar}
          >
            Recusar Aprovação
          </Button>
          <Button
            onClick={handleAceitar}
            className={classes.acceptButtonContent}
            dataCy="botao-aceitar"
            disabled={!enabledAceitar}
          >
            Aceitar Aprovação
          </Button>
          <PopperComponent
            anchorEl={alert}
          >
            <AlertCard
              icone={<CircleAlertYellowIcon width="12" height="12" />}
              title="As condições não foram aceitas"
              colorBase={colors.alert_color_300}
              width="230px"
              alertCardContent="0px"
              lineHeight="32px"
              staff
            />
          </PopperComponent>
          {openAlertModal && (
            <AlertModalInput
              openAlertModalInput={openAlertModal}
              setOpenAlertModalInput={setOpenAlertModal}
              inputValue={motivo}
              setInputValue={setMotivo}
              colorBase="rgba(245, 142, 134, 0.08)"
              icone={<AlertFilledIcon width="8" height="8" />}
              title="Justificativa"
              subtitle="É necessário apresentar uma justificativa para prosseguir com essa ação"
              alertCardTitle="A justificativa é obrigatória."
              placeholder="Insira a justificativa (obrigatório) *"
              buttonAction={handleRecusar}
              textRedButton="Confirmar"
            />
          )}
        </div>
      </div>
    </BasicDrawer>
  );
};
DrawerProposta.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  enviarProposta: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  detailData: PropTypes.object,
  historicoIsLoading: PropTypes.bool,
  historicoLimite: PropTypes.array,
  enabledAceitar: PropTypes.bool,
  enabledRecusar: PropTypes.bool,
  salvarJustificativa: PropTypes.func,
  closeModal: PropTypes.func,
};

DrawerProposta.defaultProps = {
  open: false,
  setOpen: () => { },
  enviarProposta: () => { },
  title: '',
  subtitle: '',
  detailData: {},
  historicoIsLoading: false,
  historicoLimite: [],
  enabledAceitar: true,
  enabledRecusar: true,
  salvarJustificativa: () => {},
  closeModal: () => {},
};

export default DrawerProposta;
