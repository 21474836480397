import * as React from "react";

function FilterIconRounded(props) {
return (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
	  <path fill={props.color?"white":null} fillRule="evenodd" clipRule="evenodd" d="M4.57942 1.01458C3.84057 1.01458 3.24161 1.60509 3.24161 2.33353C3.24161 3.06196 3.84057 3.65247 4.57942 3.65247C5.31827 3.65247 5.91723 3.06196 5.91723 2.33353C5.91723 1.60509 5.31827 1.01458 4.57942 1.01458ZM2.26861 1.82624C2.50355 0.781389 3.44893 0 4.57942 0C5.70991 0 6.65529 0.781389 6.89022 1.82624H22.4855C22.7696 1.82624 23 2.05336 23 2.33353C23 2.61369 22.7696 2.84081 22.4855 2.84081H6.89022C6.65529 3.88566 5.70991 4.66705 4.57942 4.66705C3.44893 4.66705 2.50355 3.88566 2.26861 2.84081H0.514541C0.230368 2.84081 0 2.61369 0 2.33353C0 2.05336 0.230368 1.82624 0.514541 1.82624H2.26861ZM16.1098 7.50786C16.3447 6.46302 17.2901 5.68163 18.4206 5.68163C19.5511 5.68163 20.4965 6.46302 20.7314 7.50786H22.4855C22.7696 7.50786 23 7.73498 23 8.01515C23 8.29532 22.7696 8.52244 22.4855 8.52244H20.7314C20.4965 9.56729 19.5511 10.3487 18.4206 10.3487C17.2901 10.3487 16.3447 9.56729 16.1098 8.52244H0.514541C0.230368 8.52244 0 8.29532 0 8.01515C0 7.73498 0.230368 7.50786 0.514541 7.50786H16.1098ZM18.4206 6.6962C17.6817 6.6962 17.0828 7.28672 17.0828 8.01515C17.0828 8.74359 17.6817 9.3341 18.4206 9.3341C19.1594 9.3341 19.7584 8.74359 19.7584 8.01515C19.7584 7.28672 19.1594 6.6962 18.4206 6.6962ZM9.16347 13.1895C9.3984 12.1446 10.3438 11.3633 11.4743 11.3633C12.6048 11.3633 13.5501 12.1446 13.7851 13.1895H22.4855C22.7696 13.1895 23 13.4166 23 13.6968C23 13.9769 22.7696 14.2041 22.4855 14.2041H13.7851C13.5501 15.2489 12.6048 16.0303 11.4743 16.0303C10.3438 16.0303 9.3984 15.2489 9.16347 14.2041H0.514541C0.230368 14.2041 0 13.9769 0 13.6968C0 13.4166 0.230368 13.1895 0.514541 13.1895H9.16347ZM11.4743 12.3778C10.7354 12.3778 10.1365 12.9683 10.1365 13.6968C10.1365 14.4252 10.7354 15.0157 11.4743 15.0157C12.2131 15.0157 12.8121 14.4252 12.8121 13.6968C12.8121 12.9683 12.2131 12.3778 11.4743 12.3778Z" />
  </svg>
);
}

export default FilterIconRounded;