import { connect } from 'react-redux';

import DrawerProposta from './drawerProposta';
import operations from '../../redux/operations';

const mapStateToProps = ({ limitesAprovados }) => ({
  historicoLimite: limitesAprovados.limiteDetails.historico,
  historicoIsLoading: limitesAprovados.limiteDetails.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  enviarProposta: (idLimite, codigoBuc, status) => {
    dispatch(
      operations.updateAndSaveStatus(idLimite, codigoBuc, status),
    );
  },
  salvarJustificativa: (idLimite, codigoBuc, status, motivo) => dispatch(
    operations.updateComJustificativa(idLimite, codigoBuc, status, motivo),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerProposta);
