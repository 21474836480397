import moment from 'moment';
import actions from './actions';
import service from './service';

const setStatusLeituraFirstGet = (notifications) => async (dispatch) => {
  const nlidas = notifications.filter((n) => n.lido === false);

  if (nlidas.length > 0) {
    const hasErrorNotifications = nlidas.filter(
      (n) => n.detalheTipo.includes('reprovado') || n.descricao.includes('reprovado') || n.descricao.includes('não efetivado'),
    );
    const hasSuccessNotifications = nlidas.filter(
      (n) => n.detalheTipo.includes('aprovado') || n.descricao.includes('aprovado') || n.descricao.includes('efetivado'),
    );
    if (hasSuccessNotifications.length > 0) {
      await dispatch(actions.setHasSuccessNotifications(true));
    }
    if (hasErrorNotifications.length > 0) {
      await dispatch(actions.setHasErrorNotifications(true));
    }
  } else {
    await dispatch(actions.setHasErrorNotifications(false));
    await dispatch(actions.setHasSuccessNotifications(false));
  }
};

const getNotifications = (dealerGroups) => async (dispatch) => {
  try {
    await dispatch(actions.initNotificationsRequest());
    const notifications = await service.getNotifications(dealerGroups);
    await dispatch(actions.setNotifications(
      notifications.data.notificacoes,
      notifications.data.exibirAlarme,
    ));
    await dispatch(setStatusLeituraFirstGet(notifications.data.notificacoes));
    dispatch(actions.finishNotificationsRequest(false, moment().format()));
  } catch (error) {
    await dispatch(actions.setNotifications([], false));
    dispatch(actions.finishNotificationsRequest(true));
  }
};

const setStatusLeituraLastGet = (notifications) => async (dispatch) => {
  const nlidas = notifications.filter((n) => n.lido === false);

  if (nlidas.length > 0) {
    const hasErrorNotifications = nlidas.filter((n) => n.detalheTipo === 'Pagamento reprovado');
    const hasSuccessNotifications = nlidas.filter((n) => n.detalheTipo === 'Pagamento aprovado');
    if (hasSuccessNotifications.length > 0) {
      await dispatch(actions.setHasSuccessNotifications(true));
    }
    if (hasErrorNotifications.length > 0) {
      await dispatch(actions.setHasErrorNotifications(true));
    }
  }
};

const getNotificationsLastGet = (dealerGroups, lastGet) => async (dispatch) => {
  try {
    await dispatch(actions.initNotificationsRequestLastGet());
    const notifications = await service.getNotifications(dealerGroups, lastGet);
    await dispatch(actions.concatNotifications(
      notifications.data.notificacoes,
      notifications.data.exibirAlarme,
    ));
    await dispatch(setStatusLeituraLastGet(notifications.data.notificacoes));
    dispatch(actions.finishNotificationsRequestLastGetSuccess(moment().format()));
  } catch (error) {
    dispatch(actions.finishNotificationsRequestLastGetError());
  }
};

const markNotificationsRead = (notifications) => async (dispatch) => {
  try {
    await dispatch(actions.initNotificationsPost());
    await service.markNotificationsRead(notifications);
    dispatch(actions.finishNotificationsPost(false));
  } catch (error) {
    dispatch(actions.finishNotificationsPost(true));
  }
};

export default {
  setStatusLeituraFirstGet,
  getNotifications,
  setStatusLeituraLastGet,
  getNotificationsLastGet,
  markNotificationsRead,
};
