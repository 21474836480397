import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { useHistory } from 'react-router-dom';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import FilterIconRounded from '../../../../assets/icons/filter-icon-rounded';
import WarningRedIcon from '../../../../assets/icons/warning-red';
import CheckCircleIcon from '../../../../assets/icons/check-circle';

import Date from '../../../common/date';

import './notificationsRow.scss';

const dateFormat = (date) => {
  const today = moment();
  return moment(date).isSame(today, 'day') ? 'HH:mm' : 'DD/MM';
};

const getIcon = (notification) => {
  if (notification?.tipo === 'fd_limite_aguardando_aceite') {
    return (
      <div className="notifications-row-filter-icon-container">
        <FilterIconRounded className="notifications-row-filter-icon" />
      </div>
    );
  }

  const isAprovado = notification?.detalheTipo?.includes('aprovad') || notification?.descricao?.includes('aprovad');

  if (isAprovado) {
    return (
      <div className="notifications-row-aproved-icon-container">
        <CheckCircleIcon className="notifications-row-aproved-icon" />
      </div>
    );
  }

  return (
    <div className="notifications-row-reproved-icon-container">
      <WarningRedIcon className="notifications-row-reproved-icon" />
    </div>
  );
};

const NotificationRow = ({
  className, notification, onClose,
}) => {
  const history = useHistory();

  const handleActionClick = () => {
    const detalhes = JSON.parse(notification.detalhes);
    const { codigoDealer } = detalhes;

    history.push('/solicitacao-credito/aprovacoes/limites-aprovados', { codigoDealer });
    onClose();
  };

  return (
    <div className={`notifications-row ${className}`}>
      <div className="notifications-row-icon">
        {getIcon(notification)}
      </div>
      <div className="notifications-row-info">
        <div className="notifications-row-info-title">{notification.detalheTipo}</div>

        <Date
          className="notifications-row-info-date"
          format={dateFormat(notification.dataHora)}
          date={notification.dataHora}
        />

        <div className="notifications-row-info-descricao">{notification.descricao}</div>
      </div>
      {notification?.tipo === 'fd_limite_aguardando_aceite' && (
      <button
        type="button"
        className="notifications-row-action"
        onClick={handleActionClick}
      >
        <span>Ver</span>
        <ArrowForwardRoundedIcon className="notifications-row-action-icon" />
      </button>
      )}
    </div>
  );
};

NotificationRow.propTypes = {
  className: PropTypes.string,
  notification: PropTypes.shape({
    dataHora: PropTypes.string,
    tipo: PropTypes.string,
    detalheTipo: PropTypes.string,
    lido: PropTypes.bool,
    itemId: PropTypes.number,
    descricao: PropTypes.string,
    detalhes: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

NotificationRow.defaultProps = {
  className: '',
};

export default NotificationRow;
