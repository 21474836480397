import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import EmptyStateIcon from 'assets/icons/empty-state';
import NotificationRow from '../notificationRow';

import { PageTitle, ErroConexao } from '../../../common';

const NotificationContent = ({
  isOpen, getNotifications, markNotificationsRead, dealerGroups, notifications,
  isError, loadingPost, loading, isPostSending, isErrorLastGet, onClose,
}) => {
  const postReadNotifications = async () => {
    const unreadNotifications = notifications.filter((n) => n.lido === false);
    if (unreadNotifications.length > 0) {
      await markNotificationsRead(unreadNotifications);
    }
    await getNotifications(dealerGroups);
  };

  useEffect(() => {
    if (isOpen && !loadingPost && !loading && !isPostSending) {
      postReadNotifications();
    }
  }, [isOpen, loadingPost, loading, isPostSending, notifications]);

  const contentForStep = (notificationsList, hasError, hasErrorLastGet) => {
    if ((hasError && hasErrorLastGet) || notificationsList.length === 0) {
      return (
        <ErroConexao
          title="Sem resultados."
          message="ainda não há nada por aqui"
        >
          <EmptyStateIcon className="erro-conexao-image" />
        </ErroConexao>
      );
    }

    return notificationsList.map((notification, index) => (
      <NotificationRow
        notification={notification}
        key={`notification-row${index}`}
        onClose={onClose}
      />
    ));
  };

  return (
    <div>
      <PageTitle className="notifications-page-title" title="Notificações" />
      {contentForStep(notifications, isError, isErrorLastGet)}
    </div>
  );
};

NotificationContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  getNotifications: PropTypes.func.isRequired,
  markNotificationsRead: PropTypes.func.isRequired,
  dealerGroups: PropTypes.array.isRequired,
  notifications: PropTypes.array.isRequired,
  isError: PropTypes.bool.isRequired,
  loadingPost: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isPostSending: PropTypes.bool.isRequired,
  isErrorLastGet: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationContent;
