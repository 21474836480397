import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'routes/history';
import { AuthReducer } from 'modules/auth/redux';
import { DealerReducer } from 'modules/dealers/redux';
import { SnackbarReducer } from 'modules/snackbar';

import { BalanceReducer } from 'features/balance/redux';
import StatementReducer from 'features/statement/redux/reducer';
import { PaymentReducer } from 'features/payment/redux';
import { IofSimulatorReducer } from 'features/iofSimulator/redux';
import TestDriveReducer from 'features/testDrive/redux';
import NotificationsReducer from 'features/notification/redux/reducer';
import { reducer as FeedbackReducer } from 'features/userFeedback/redux';
import { reducer as PaymentButtonReducer } from 'features/menu/view/paymentButton/redux';
import LimiteReducer from 'features/solicitacaoLimite/redux/reducer';
import LimitesAprovadosReducer from 'features/solicitacaoLimite/views/aprovacoes/limitesAprovadosPage/redux/reducer';
import LimitesAprovadosCadastroReducer from 'features/solicitacaoLimite/views/aprovacoes/limitesAprovadosCadastro/redux/reducer';
import LimitesAprovadosObservacaoReducer from 'features/solicitacaoLimite/views/aprovacoes/observacoesAprovacao/redux/reducer';
import MensagemRedeReducer from 'features/mensagemRede/redux/reducer';
import GestaoPagamentoReducer from 'features/gestaoPagamentos/redux/reducer';
import Dashboard from 'features/dashboard/redux/reducer';
import GestaoComunicados from 'features/communicationCenter/redux/reducer';
import GestaoRelatorios from 'features/gestaoRelatorios/redux/reducer';

export default combineReducers({
  router: connectRouter(history),
  authStore: AuthReducer,
  dealerStore: DealerReducer,
  snackbar: SnackbarReducer,

  limiteCreditoStore: LimiteReducer,
  mensagensRedeStore: MensagemRedeReducer,

  balanceStore: BalanceReducer,
  statementStore: StatementReducer,
  paymentStore: PaymentReducer,
  notificationStore: NotificationsReducer,

  iofSimulatorStore: IofSimulatorReducer,
  testDrive: TestDriveReducer,

  feedback: FeedbackReducer,
  paymentButton: PaymentButtonReducer,

  gestaoPagamentos: GestaoPagamentoReducer,
  dashboard: Dashboard,
  comunicados: GestaoComunicados,
  relatoriosIof: GestaoRelatorios,

  limitesAprovados: LimitesAprovadosReducer,
  limitesAprovadosCadastro: LimitesAprovadosCadastroReducer,
  limitesAprovadosObservacao: LimitesAprovadosObservacaoReducer,
});
