import { connect } from 'react-redux';

import selectors from '../../redux/selectors';
import operations from '../../redux/operations';
import NotificationContent from './notificationContent';

const mapDispatchToProps = (dispatch) => ({
  getNotifications: (dealerGroups) => (
    dispatch(operations.getNotifications(dealerGroups))
  ),
  markNotificationsRead: (notifications) => (
    dispatch(operations.markNotificationsRead(notifications))
  ),
});

const mapStateToProps = ({ notificationStore, dealerStore }) => ({
  notifications: notificationStore.notifications.listNotifications,
  isError: notificationStore.isError,
  loadingPost: notificationStore.loadingPost,
  isPostSending: notificationStore.isPostSending,
  loading: notificationStore.loading,
  isErrorLastGet: notificationStore.isErrorLastGet,
  dealerGroups: selectors.getDealerGroups(dealerStore),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContent);
