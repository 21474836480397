import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { AuthSelectors } from 'modules/auth/redux';
import * as TdPageOperations from 'features/testDrive/redux/operations';

import MensagemRedeOperations from 'features/mensagemRede/redux/operations';

import selector from 'routes/redux/selector';
import MenuAside from './menuAside';

const mapStateToProps = ({
  authStore, notificationStore, mensagensRedeStore, dealerStore,
}) => ({
  permissions: authStore.permissions,
  hasErrorNotification: notificationStore.hasErrorNotification,
  hasSuccessNotification: notificationStore.hasSuccessNotification,
  exibirAlarme: notificationStore.notifications.exibirAlarme,
  user: authStore.user,
  hasLimitesPermission: AuthSelectors.hasLimitesPermission(authStore),
  hasFidcPermission: AuthSelectors.hasFidcPermission(authStore),
  isModalMensagensRedeOpen: mensagensRedeStore.isModalOpen,
  isBrand: selector.isBrand(dealerStore),
});

const mapDispatchToProps = (dispatch) => ({
  navTo: (path) => dispatch(push(path)),
  setTdInitialPage: () => dispatch(TdPageOperations.setPage(TdPageOperations.PAGE_PRODUTO)),
  getMensagensRede: () => dispatch(MensagemRedeOperations.getMensagensRede()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MenuAside);
