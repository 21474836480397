/* eslint-disable camelcase */
import {
  React, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import parse from 'html-react-parser';

import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import colors from 'assets/styles/colors';
import moment from 'moment';

import MultiplosAnexos from '../../multiplosAnexos/multiplosAnexos';
import ExpandButton from './expandButton';

const ObservacoesItem = ({
  item, isLastItem, downloadDocumento, isDrawer,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);
  const {
    primary_color_300,
    secundary_color_100,
    secundary_color_500,
    secundary_color_700,
    error_color_600_1,
    alert_color_200,
  } = colors;

  useEffect(() => {
    if (textRef.current && textRef.current.scrollHeight > 96) {
      setShowButton(true);
    }
  }, []);

  const getPerfilUsuario = () => {
    switch (item?.perfil) {
      case 'cadastro':
        return 'Cadastro';
      case 'credito':
        return 'Crédito';
      case 'dealer':
        return 'Dealer';
      case 'financiamento':
        return 'Finan. Rede';
      case 'juridico':
        return 'Jurídico';
      default:
        return 'N/A';
    }
  };

  const getNomeUsuario = () => (
    item?.usuarioNome
      ? item?.usuarioNome
      : item?.usuarioEmail.split('@', 1)[0] ?? 'N/A'
  );

  const getData = () => moment(item?.criadoEm).format('DD/MM/YYYY');

  const getHora = () => moment(item?.criadoEm).format('HH:mm');

  const getTruncatedText = (text, maxLength, tooltip) => (
    <Box
      component="span"
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: `${maxLength}px`,
      }}
      title={tooltip}
    >
      {text}
    </Box>
  );

  const renderInfo = (
    Icon,
    firstText,
    secondText,
    iconColor = primary_color_300,
    textColor = secundary_color_500,
  ) => {
    const maxLength = isDrawer ? 170 : 260;
    return (
      <Stack height="16px" direction="row" alignItems="center" gap="4px">
        <Icon htmlColor={iconColor} style={{ fontSize: '12px' }} />
        <Box
          component="span"
          sx={{
            color: textColor,
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 450,
            display: 'inline-flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {secondText === null
            ? getTruncatedText(firstText, maxLength, firstText)
            : (
              <>
                {getTruncatedText(firstText, maxLength, null)}
                {' | '}
                {getTruncatedText(secondText, maxLength, secondText)}
              </>
            )}
        </Box>
      </Stack>
    );
  };

  return (
    <Stack padding="12px 0px" borderBottom={isLastItem ? 'none' : `1px solid ${secundary_color_100}`} gap="1px">
      <Box
        fontSize="14px"
        lineHeight="16px"
        fontWeight="450"
        color={secundary_color_700}
        ref={textRef}
        sx={{
          maxHeight: expanded ? 'none' : '96px',
          overflow: 'hidden',
          position: 'relative',
          display: '-webkit-box',
          WebkitLineClamp: expanded ? 'none' : '6',
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          '& > p': { margin: 0 },
        }}
      >
        {isDrawer
          ? parse(`${item?.detalhes && item.detalhes.replace(/\|/g, '|<br />') ? `${item.detalhes.replace(/\|/g, '<br />')}<br /><br />` : ''}${item?.observacao}`)
          : parse(`${item?.detalhes ? `${item.detalhes}<br /><br />` : ''}${item?.observacao}`)}
      </Box>
      {showButton && (
        <ExpandButton
          expanded={expanded}
          onClick={() => setExpanded(!expanded)}
        />
      )}
      <MultiplosAnexos
        downloadDocumento={downloadDocumento}
        documentos={item.anexos}
        disableUpload
      />
      {isDrawer ? (
        <>
          <Stack height="16px" direction="row" alignItems="center" gap="8px" whiteSpace="no-wrap">
            {item?.notificacaoSistema
              ? renderInfo(NotificationImportantRoundedIcon, 'Notificação do Sistema', getNomeUsuario())
              : renderInfo(PersonRoundedIcon, getPerfilUsuario(), getNomeUsuario())}
          </Stack>
          <Stack height="16px" direction="row" alignItems="center" gap="8px" whiteSpace="no-wrap">
            {renderInfo(AccessTimeRoundedIcon, getData(), getHora())}
            {item?.anexoRemovido && (
              renderInfo(CloseRoundedIcon, 'Anexo invalidado', null, error_color_600_1, error_color_600_1)
            )}
            {item?.justificativa && (
              renderInfo(
                WarningRoundedIcon,
                item.justificativa,
                null,
                alert_color_200,
                alert_color_200,
              )
            )}
          </Stack>
        </>
      ) : (
        <Stack height="16px" direction="row" alignItems="center" gap="8px" whiteSpace="no-wrap">
          {item?.notificacaoSistema
            ? renderInfo(NotificationImportantRoundedIcon, 'Notificação do Sistema', getNomeUsuario())
            : renderInfo(PersonRoundedIcon, getPerfilUsuario(), getNomeUsuario())}
          {renderInfo(AccessTimeRoundedIcon, getData(), getHora())}
          {item?.anexoRemovido && (
            renderInfo(CloseRoundedIcon, 'Anexo invalidado', null, error_color_600_1, error_color_600_1)
          )}
          {item?.justificativa && (
            renderInfo(
              WarningRoundedIcon,
              item.justificativa,
              null,
              alert_color_200,
              alert_color_200,
            )
          )}
        </Stack>
      )}
    </Stack>
  );
};

ObservacoesItem.propTypes = {
  item: PropTypes.object,
  isLastItem: PropTypes.bool,
  downloadDocumento: PropTypes.func,
  isDrawer: PropTypes.bool,
};

ObservacoesItem.defaultProps = {
  item: null,
  isLastItem: false,
  downloadDocumento: () => { },
  isDrawer: false,
};

export default ObservacoesItem;
