import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Loading from 'features/common-staff/layout/loading';
import SummaryPage from 'features/common/summaryPage';
import InfoDocumentosComplementares from './infoDocumentosComplementares';
import { getDocumentStatus, renderStatusItens } from './documentosComplementaresStatus/statusUtils';

const DocumentosJuridico = ({
  listaDocumentosComplementares, isLoading,
}) => {
  const renderDocumentosComplementares = !_.isEmpty(listaDocumentosComplementares);
  const renderItemStatusDealer = () => {
    const statusSummary = listaDocumentosComplementares?.flatMap(getDocumentStatus)
      .filter((status) => status ?? null);
    return (renderStatusItens(statusSummary));
  };
  return (
    isLoading
      ? (
        <div style={{ padding: '24px' }}>
          <Loading />
        </div>
      )
      : (renderDocumentosComplementares)
        && (
          <SummaryPage
            title="Documentos complementares"
            IconTitle={renderItemStatusDealer()}
          >
            { renderDocumentosComplementares && (
              listaDocumentosComplementares?.map((item, index) => (
                <InfoDocumentosComplementares
                  key={index}
                  indexPessoa={index}
                  pessoaDocumentacao={item}
                />
              ))
            )}
          </SummaryPage>
        )
  );
};

DocumentosJuridico.propTypes = {
  listaDocumentosComplementares: PropTypes.array,
  isLoading: PropTypes.bool,
};

DocumentosJuridico.defaultProps = {
  listaDocumentosComplementares: [],
  isLoading: false,
};

export default DocumentosJuridico;
