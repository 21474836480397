import { connect } from 'react-redux';

import selectors from '../../redux/selectors';
import operations from '../../redux/operations';
import NotificationDrawer from './notificationDrawer';

const mapDispatchToProps = (dispatch) => ({
  getNotifications: (cnpjRoot) => (
    dispatch(operations.getNotifications(cnpjRoot))
  ),
  getNotificationsLastGet: (dealerGroups, lastGet) => (
    dispatch(operations.getNotificationsLastGet(dealerGroups, lastGet))
  ),
});

const mapStateToProps = ({ notificationStore, dealerStore }) => ({
  dealerGroups: selectors.getDealerGroups(dealerStore),
  lastGet: notificationStore.lastGet,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDrawer);
