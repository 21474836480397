import logger from 'utils/logger';
import { SnackbarActions } from 'modules/snackbar';
import _ from 'lodash';
import serviceLimites from '../../limitesAprovadosPage/redux/service';
import actions from './actions';
import service from './service';

const setSelectedItems = (idTipo) => (dispatch) => {
  dispatch(actions.setSelectedItems(idTipo));
};

const buildGerenciadorDocumentos = (listaPessoaDocumentacao) => (dispatch) => {
  const gerenciadorDocumentos = listaPessoaDocumentacao?.flatMap(
    (item) => item.pessoaDocumentacao.tipoDocumento.map((doc) => {
      const documentoCadastro = item.documentosCadastro
        .find((documento) => doc === documento.nomeDocumento);

      let cadastroResult = {};
      if (documentoCadastro) {
        cadastroResult = {
          id: documentoCadastro.id,
          nome: documentoCadastro.nomeOriginal,
          nomeGuid: documentoCadastro.nomeGuid,
          tamanho: documentoCadastro.tamanho,
          idPessoaDocumento: item.pessoaDocumentacao.idPessoaDocumentacao,
          nomeDocumento: doc,
        };
      }

      return {
        nome: item.documentos
          .find((documento) => doc === documento.nomeDocumento)?.nomeOriginal,
        tamanho: item.documentos
          .find((documento) => doc === documento.nomeDocumento)?.tamanho,
        idPessoaDocumento: item.pessoaDocumentacao.idPessoaDocumentacao,
        nomeDocumento: doc,
        validado: item.documentos
          .find((documento) => doc === documento.nomeDocumento)?.validado,
        isLoading: false,
        isError: false,
        idDocumento: item.documentos
          .find((documento) => doc === documento.nomeDocumento)?.idPessoaDocumentacao || null,
        documentosCadastro: cadastroResult,
      };
    }),
  );
  dispatch(actions.buildGerenciadorDocumentos(gerenciadorDocumentos));
};

const getPessoaDocumentacao = (idLimite) => async (dispatch) => {
  try {
    dispatch(actions.getPessoaDocumentacaoStart());
    const listaPessoaDocumentacao = await service.getPessoaDocumentacao(idLimite);
    dispatch(actions.getPessoaDocumentacaoSuccess(
      listaPessoaDocumentacao,
    ));
    dispatch(buildGerenciadorDocumentos(listaPessoaDocumentacao));
  } catch (e) {
    logger.error(e);
    dispatch(actions.getPessoaDocumentacaoError());
  }
};

const updateAndSaveStatus = (
  idLimite,
  status,
  documentosFormalizar = null,
  documentosComplementares = null,
  documentosCadastro = null,
) => async (dispatch) => {
  try {
    dispatch(actions.updateLimiteStart());
    const response = await serviceLimites.updateAndSaveStatus({
      status,
      idLimiteList: [idLimite],
      tipo: 'alterar_status',
      ...(documentosFormalizar && { documentosFormalizar }),
      ...(documentosComplementares && { documentosComplementares }),
      ...(documentosCadastro && { documentosCadastro }),
    });
    if (response.status !== 200) {
      throw new Error('Erro ao enviar lista de documentos.');
    } else {
      dispatch(SnackbarActions.addSnackbar('Documentos enviados com sucesso.', 'success'));
      dispatch(actions.updateLimiteSuccess());
      return true;
    }
  } catch (e) {
    dispatch(actions.updateLimiteError());
    dispatch(SnackbarActions.addSnackbar('Erro ao enviar lista de documentos.', 'error'));
    logger.error(e);
    return false;
  }
};

const downloadDocumento = (
  idDocumento,
  idPessoaDocumento,
  nomeDocumento,
) => async (dispatch, getState) => {
  const { gerenciadorDocumentos } = getState().limitesAprovadosCadastro;
  let documentos = [...gerenciadorDocumentos];
  const documento = documentos.find(
    (objeto) => objeto.idPessoaDocumento === idPessoaDocumento
      && objeto.nomeDocumento === nomeDocumento,
  );
  try {
    documento.isLoading = true;
    documento.isError = false;
    dispatch(actions.downloadDocumentoStart(gerenciadorDocumentos));
    const urlDownload = await service.getUrlDownloadDocumento(idDocumento);
    documentos = [...gerenciadorDocumentos];
    documento.isLoading = false;
    documento.isError = false;
    dispatch(actions.downloadDocumentoSuccess(documentos, urlDownload));
    if (urlDownload) window.open(urlDownload);
    return urlDownload;
  } catch (e) {
    logger.error(e);
    documento.isLoading = false;
    documento.isError = true;
    dispatch(actions.downloadDocumentoError(gerenciadorDocumentos));
  }
  return '';
};

const setErroDocumento = (
  idPessoaDocumento,
  nomeDocumento,
  textoErro,
) => async (dispatch, getState) => {
  const { gerenciadorDocumentos } = getState().limitesAprovadosCadastro;
  const documentos = [...gerenciadorDocumentos];
  const documento = documentos.find(
    (objeto) => objeto.idPessoaDocumento === idPessoaDocumento
      && objeto.nomeDocumento === nomeDocumento,
  );
  documento.isError = true;
  documento.textoErro = textoErro;
  dispatch(actions.deleteDocumentoStart(documentos));
};

const getDocumentosComplementares = (idLimite) => async (dispatch) => {
  try {
    dispatch(actions.getDocumentosComplementaresStart());
    const listaDocumentosComplementares = await service.getDocumentosComplementares(idLimite);
    dispatch(actions.getDocumentosComplementaresSuccess(listaDocumentosComplementares));
  } catch (e) {
    logger.error(e);
    dispatch(actions.getDocumentosComplementaresError());
  }
};

const updateObservacaoDealer = (idPessoa, observacao) => async (dispatch) => {
  try {
    await service.updateObservacaoDealer(idPessoa, observacao);
    dispatch(SnackbarActions.addSnackbar('Observação atualizada com sucesso.', 'success'));
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao enviar observação.', 'error'));
  }
};

const updateObservacaoDealerCadastro = (id, observacao) => async (dispatch) => {
  try {
    await service.updateObservacaoDealerCadastro(id, observacao);
    dispatch(SnackbarActions.addSnackbar('Observação atualizada com sucesso!', 'success'));
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao enviar observação.', 'error'));
  }
};

const uploadDocumentoComplementar = (
  file,
  idPessoaTipoDocumentoJuridico,
  indexPessoa,
  indexDocumento,
) => async (dispatch) => {
  try {
    const response = await service.getUrlUploadTemp(file);

    if (response) {
      const { urlUpload, ...documento } = response;
      const responseUpload = await service.uploadDocumento(urlUpload, file);

      if (responseUpload) {
        const anexo = {
          nomeGuid: documento.nomeGuid,
          nome: documento.nomeOriginal,
          tamanho: documento.tamanhoEmMb,
          type: documento.type,
          temporario: true,
          IdPessoaTipoDocumentoJuridico: idPessoaTipoDocumentoJuridico,
        };
        dispatch(actions.insertDocumentosComplementar(indexPessoa, indexDocumento, anexo));
        dispatch(SnackbarActions.addSnackbar('Documento complementar inserido com sucesso.', 'success'));
        return { ...anexo, file };
      }
    }
    throw new Error();
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao inserir documento complementar.', 'error'));
    return false;
  }
};

const getDocumentoComplementar = (idDocumento) => async (dispatch) => {
  try {
    const response = await service.getDocumentoComplementarUrl(idDocumento);
    window.open(response, '_blank');
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao abrir documento complementar.', 'error'));
  }
};

const deleteDocumentoComplementar = (
  idDocumento,
  indexPessoa,
  indexDocumento,
) => async (dispatch) => {
  try {
    dispatch(actions.deleteDocumentosComplementar(idDocumento, indexPessoa, indexDocumento));
    dispatch(SnackbarActions.addSnackbar('Documento complementar excluído com sucesso.', 'success'));
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao excluir documento complementar.', 'error'));
  }
};

const getDocumentosFormalizar = (id) => async (dispatch) => {
  try {
    dispatch(actions.getDocumentoFormalizarStart());
    const documentoList = await service.getDocumentosFormalizar(id);

    dispatch(actions.getDocumentoFormalizarSuccess(documentoList));
  } catch (e) {
    logger.error(e);
    dispatch(actions.getDocumentoFormalizarError());
  }
};

const downloadAnexoDocumentoFormalizar = (idDocumento) => async (dispatch) => {
  try {
    const response = await service.getAnexoDocumentoFormalizar(idDocumento);

    if (response) {
      window.open(response);
    }
  } catch (e) {
    dispatch(SnackbarActions.addSnackbar('Erro ao baixar documento'));
    logger.error(e);
  }
};

const updateDocumentoFormalizar = (observacao, indexList) => async (dispatch, getState) => {
  try {
    dispatch(actions.updateDocumentoFormalizarStart());
    const dados = getState()
      .limitesAprovadosCadastro.listaDocumentosFormalizar.listaDados[indexList];

    const response = await service.updateDocumentosFormalizar(observacao, dados);

    dispatch(actions.updateDocumentoFormalizarSuccess(response));
    dispatch(SnackbarActions.addSnackbar('Observação atualizada com sucesso', 'success'));
  } catch (e) {
    dispatch(SnackbarActions.addSnackbar('Erro ao enviar observação', 'error'));
    logger.error(e);
  }
};

const uploadAnexoDocumentoFormalizar = (
  file,
  idDocumentoFormalizarJuridico,
  indexList,
) => async (dispatch) => {
  try {
    const response = await service.getUrlUploadTemp(file);

    if (response) {
      const { urlUpload, ...documento } = response;
      const responseUpload = await service.uploadDocumento(urlUpload, file);

      if (responseUpload) {
        const anexo = {
          nomeGuid: documento.nomeGuid,
          nomeOriginal: documento.nomeOriginal,
          tamanho: documento.tamanhoEmMb,
          idDocumentoFormalizarJuridico,
          tipo: 'dealer',
        };
        dispatch(actions.insertAnexoDocumentoFormalizar(indexList, anexo));
        dispatch(SnackbarActions.addSnackbar('Documento inserido com sucesso.', 'success'));
        return { ...anexo, file };
      }
    }
    throw new Error();
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao inserir documento.', 'error'));
    return false;
  }
};

const deleteAnexoDocumentoFormalizar = (nomeGuid, indexList) => async (dispatch) => {
  try {
    dispatch(actions.deleteAnexoDocumentoFormalizar(indexList, nomeGuid));
    dispatch(SnackbarActions.addSnackbar('Documento excluído com sucesso.', 'success'));
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao excluir documento.', 'error'));
  }
};

const removeNewAnexosDocumentoFormalizar = () => async (dispatch, getState) => {
  const documentoList = getState().limitesAprovadosCadastro.listaDocumentosFormalizar.listaDados;

  documentoList.forEach((documento, indexDocumento) => {
    const documentosDealer = documento?.listaArquivos?.filter((arquivo) => arquivo?.tipo === 'dealer');
    documentosDealer.forEach((anexo) => {
      if (anexo.nomeGuid !== '' && (_.isNull(anexo.id) || _.isUndefined(anexo.id))) {
        dispatch(actions.deleteAnexoDocumentoFormalizar(indexDocumento, anexo.nomeGuid));
      }
    });
  });
};

const uploadDocumentoCadastro = (
  file,
  idPessoaDocumento,
  nomeDocumento,
  indexDocumento,
) => async (dispatch) => {
  try {
    const response = await service.getUrlUploadTemp(file);

    if (response) {
      const { urlUpload, ...documento } = response;
      const responseUpload = await service.uploadDocumento(urlUpload, file);

      if (responseUpload) {
        const anexo = {
          nomeGuid: documento.nomeGuid,
          nome: documento.nomeOriginal,
          tamanho: documento.tamanhoEmMb,
          temporario: true,
          idPessoaDocumento,
        };

        dispatch(actions.insertDocumentoCadastro(
          indexDocumento,
          idPessoaDocumento,
          nomeDocumento,
          anexo,
        ));
        dispatch(SnackbarActions.addSnackbar('Documento de cadastro inserido com sucesso.', 'success'));
        return { ...anexo, file };
      }
    }
    throw new Error();
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao inserir documento de cadastro.', 'error'));
    return false;
  }
};

const downloadDocumentoDoCadastro = (
  idDocumento,
) => async () => {
  try {
    const urlDownload = await service.getUrlDownloadDocumentoDoCadastro(idDocumento);
    if (urlDownload) window.open(urlDownload);
  } catch (e) {
    logger.error(e);
  }
};

const deleteDocumentoCadastro = (
  idDocumento,
  idPessoaDocumento,
  nomeDocumento,
) => async (dispatch, getState) => {
  try {
    const { gerenciadorDocumentos } = getState().limitesAprovadosCadastro;
    const indexDocumento = gerenciadorDocumentos.findIndex(
      (doc) => doc.idPessoaDocumento === idPessoaDocumento && doc.nomeDocumento === nomeDocumento,
    );
    dispatch(actions.deleteDocumentoCadastro(idDocumento, indexDocumento));
    dispatch(SnackbarActions.addSnackbar('Documento do cadastro excluído com sucesso.', 'success'));
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao excluir documento do cadastro.', 'error'));
  }
};

export default {
  setSelectedItems,
  getPessoaDocumentacao,
  updateAndSaveStatus,
  downloadDocumento,
  setErroDocumento,
  getDocumentosComplementares,
  updateObservacaoDealer,
  updateObservacaoDealerCadastro,
  uploadDocumentoComplementar,
  getDocumentoComplementar,
  deleteDocumentoComplementar,
  getDocumentosFormalizar,
  downloadAnexoDocumentoFormalizar,
  updateDocumentoFormalizar,
  uploadAnexoDocumentoFormalizar,
  deleteAnexoDocumentoFormalizar,
  removeNewAnexosDocumentoFormalizar,
  downloadDocumentoDoCadastro,
  uploadDocumentoCadastro,
  deleteDocumentoCadastro,
};
