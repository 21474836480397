import React from 'react';
import PropTypes from 'prop-types';

import Button from 'features/common-staff/controls/button';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Modal from '@material-ui/core/Modal';
import colors from 'assets/styles/colors';
import NewButton from 'features/common/newButton/newButton';
import ButtonTooltipIcon from '../buttonTooltipIcon';
import { Container, Header } from './alertModal.style';

const AlertModal = ({
  buttonAction, closeAction, title, subtitle, textRedButton, textGrayButton, openModal, setOpen,
  disableAction, isLoadingAction, closeOnSubmit, mixpanelTarget, mixpanelPage, children,
  alertCardTitle, icone, colorBase, limitesAprovados, preventAction, isNotificationAlert,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelButton = () => {
    if (closeAction) closeAction();
    handleClose();
  };

  const handleClick = () => {
    if (closeOnSubmit) handleClose();
    buttonAction();
  };

  function renderHeaderIcon(style) {
    if (isNotificationAlert) return (<NotificationImportantIcon style={{ fontSize: '30px', ...style }} />);
    return (<WarningRoundedIcon style={style} />);
  }

  const renderButtonSave = () => {
    if (limitesAprovados) {
      return (
        <NewButton
          className="gray"
          onClick={handleClick}
          preventOnClick={preventAction}
          alertCardTitle={alertCardTitle}
          icone={icone}
          colorBase={colorBase}
        >
          <span>{textRedButton}</span>
        </NewButton>
      );
    }
    return (
      <Button
        color="darkGray"
        onClick={handleClick}
        disabled={disableAction}
        isLoading={isLoadingAction}
        mixpanelTarget={mixpanelTarget}
        mixpanelPage={mixpanelPage}
      >
        {textRedButton}
      </Button>
    );
  };
  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      disableEscapeKeyDown
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container>
        <div className="modal-header">
          <div className="message">
            <Header>
              {renderHeaderIcon({ color: colors.error_color_300 })}
              <span className="title">{title}</span>
            </Header>
          </div>
          <ButtonTooltipIcon buttonAction={() => handleClose()} title="Fechar" className="close-button">
            <CloseRoundedIcon />
          </ButtonTooltipIcon>
        </div>
        <div className="modal-text-content">
          <span className="subtitle">{subtitle}</span>
        </div>
        {children && (
          <div className="modal-body">
            {children}
          </div>
        )}
        <div className="modal-footer">
          <div className="return-button">
            <Button
              color="darkGrayBorder"
              onClick={() => handleCancelButton()}
            >
              {textGrayButton}
            </Button>
          </div>
          <div>
            {renderButtonSave()}
          </div>
        </div>
      </Container>
    </Modal>
  );
};

AlertModal.propTypes = {
  setOpen: PropTypes.func,
  buttonAction: PropTypes.func,
  closeAction: PropTypes.func,
  openModal: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  textRedButton: PropTypes.string,
  textGrayButton: PropTypes.string,
  disableAction: PropTypes.bool,
  closeOnSubmit: PropTypes.bool,
  isLoadingAction: PropTypes.bool,
  mixpanelTarget: PropTypes.string,
  mixpanelPage: PropTypes.string,
  children: PropTypes.element,
  alertCardTitle: PropTypes.string,
  icone: PropTypes.element,
  colorBase: PropTypes.string,
  limitesAprovados: PropTypes.bool,
  preventAction: PropTypes.bool,
  isNotificationAlert: PropTypes.bool,
};

AlertModal.defaultProps = {
  setOpen: () => { },
  buttonAction: () => { },
  closeAction: () => { },
  openModal: false,
  title: '',
  subtitle: '',
  textRedButton: 'Salvar',
  textGrayButton: 'Voltar',
  disableAction: false,
  isLoadingAction: false,
  closeOnSubmit: true,
  mixpanelTarget: null,
  mixpanelPage: null,
  children: null,
  alertCardTitle: '',
  icone: null,
  colorBase: colors.alert_color_200,
  limitesAprovados: false,
  preventAction: false,
  isNotificationAlert: false,
};

export default AlertModal;
